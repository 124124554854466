import { useReactiveVar } from '@apollo/client/react'
import Image404 from '@assets/404.svg'
import { DefaultPageTemplate } from '@components/shared/templates/DefaultPageTemplate'
import { Button } from 'antd'
import { NextPage } from 'next'
import Image from 'next/image'
import styled from 'styled-components'
import MarketplaceHeader from '../components/marketplace/MarketplaceHeader'
import { chainIdVar } from '../graphql/variables/WalletVariable'

const Page404: NextPage = () => {
  const chainId = useReactiveVar(chainIdVar)
  return (
    <DefaultPageTemplate header={<MarketplaceHeader chainId={chainId} />}>
      <S.Container>
        <S.Content>
          <div>
            <Image layout='intrinsic' width={368} height={368} src={Image404} alt='404' />
          </div>
          <h1>This page is lost.</h1>
          <p>We’ve explore deep and wide, but we can’t find the page you were looking for.</p>
          <S.ButtonAction href={`/marketplace/${chainId}`}> Navigate back home</S.ButtonAction>
        </S.Content>
      </S.Container>
    </DefaultPageTemplate>
  )
}

export default Page404

const S = {
  Container: styled.div`
    min-height: calc(100vh - 96px);
    display: flex;
    width: 100%;
    max-width: ${props => props.theme.viewport.desktopXl};
    margin: 0 auto;
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      max-width: 368px;
      height: auto;
      position: relative;
    }

    h1 {
      font-family: ${props => props.theme.fonts.primary};
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 52px;
      color: ${props => props.theme.colors.gray[4]};
      margin-bottom: 19px;
    }

    p {
      font-family: ${props => props.theme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      color: ${props => props.theme.colors.gray[3]};
      max-width: 420px;
      text-align: center;
      margin-bottom: 41px;
    }
  `,
  ButtonAction: styled(Button)`
    border: none;
    border-radius: 8px;
    background: ${props => props.theme.colors.blue.main};
    color: ${props => props.theme.colors.white};
    width: 248px;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      background: ${props => props.theme.colors.blue.dark};
      color: ${props => props.theme.colors.white};
    }
  `
}
